<script setup lang="ts">
import { vMaska } from 'maska/vue'
import sbjs from 'sourcebuster'
import defu from 'defu'

import RModal from '~/components/Base/rModal.vue'
import { hideModal, modalState } from '~/store/modal/FreeModal'

const loading = ref(false)
const userData = reactive({
  name: '',
  email: '',
  phone: '',
  company: '',
  website: modalState.website,
})

const { $sbjs } = useNuxtApp()
const { headers } = useHeaders()
const { triggerEvent, pushData } = useTrackEvent()

function getCookiePairs() {
  const cookies = document.cookie.split(';')
  const cookiePairs = {}

  cookies.forEach((cookie) => {
    const parts = cookie.split('=')
    const key = parts[0].trim()
    const value = decodeURIComponent(parts[1])
    cookiePairs[key] = value
  })

  return cookiePairs
}
async function sendFeedback() {
  const cookiePairs = getCookiePairs()
  delete headers.value.cookie
  delete headers.value.auth_token
  const refine = defu(headers.value, cookiePairs, sbjs.get.udata)
  try {
    loading.value = true
    headers.value.host = window.location.href

    await $fetch
      .raw('https://crm.rang.ai/webhooks/forms/', {
        method: 'POST',
        mode: 'no-cors',
        body: {
          email: userData.email,
          name: userData.name,
          phone: userData.phone,
          company: userData.company,
          website: userData.website,
          form_type: 4,
          cookies: refine,
        },
      })
      .then(() => {
        triggerEvent('audit')
        pushData('audit', userData)
      })
  }
  catch (e) {
    console.error(e)
  }
  finally {
    loading.value = false
    modalState.isOpen = false
    userData.value = {}
  }
}
watch(() => modalState.isOpen, () => {
  userData.website = modalState.website
})
</script>

<template>
  <RModal v-model="modalState.isOpen">
    <div class="relative">
      <Icon
        class="absolute top-0 right-0 cursor-pointer hover:text-blue-500"
        name="mdi:close"
        @click="hideModal"
      />
      <h2 class="md:text-h2 text-m-h2">
        Получить доступ в систему управления отзывами бесплатно
      </h2>
      <p class="md:text-primary text-m-primary pt-3">
        Укажите свои данные — настроим для вас систему и свяжемся
      </p>
      <form class="pt-8 flex-col flex gap-4" @submit.prevent="sendFeedback">
        <input
          v-model="userData.name"
          class="text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Имя"
          type="text"
        >
        <input
          v-model="userData.phone"
          v-maska
          data-maska="+7 ### ### ##-##"
          class="w-1/2 text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="+7"
          type="tel"
        >
        <input
          v-model="userData.email"
          required
          class="w-2/3 text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Эл. почта"
          type="email"
        >
        <input
          v-model="userData.company"
          class="text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Компания"
          type="text"
        >
        <input
          v-model="userData.website"
          pattern=".+"
          class="text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Сайт"
          type="text"
          required
        >
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 mt-1 md:mt-5"
        >
          <RButton :is-loading="loading" class="mt-2 sm:mt-0" type="submit">
            Ок, жду
          </RButton>
          <div
            class="flex flex-col md:flex-row lg:flex-col text-primary-small whitespace-nowrap md:gap-1 lg:gap-0"
          >
            <span>Нажимая кнопку «Ок, жду», вы даёте</span>
            <NuxtLink
              target="_blank"
              to="https://rang.ai/privacy"
              class="text-secondary-300 border-b hover:text-blue-500 duration-150"
            >
              согласие на обработку персональных данных
            </NuxtLink>
          </div>
        </div>
      </form>
    </div>
  </RModal>
</template>
