<script setup lang="ts">
// global modal component
const modelValue = defineModel()

onMounted(() => {
  const closeModalOnEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && modelValue.value)
      modelValue.value = false
  }

  window.addEventListener('keydown', closeModalOnEsc)

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', closeModalOnEsc)
  })
})

watchEffect(() => {
  if (process.client) {
    const body = document.body
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (modelValue.value) {
      document.body.style.overflow = 'hidden'
      if (scrollBarWidth > 0)
        body.style.paddingRight = `${scrollBarWidth}px`
    }

    else {
      document.body.style.overflow = 'auto'
      body.style.paddingRight = '0px'
    }
  }
})
</script>

<template>
  <teleport v-if="modelValue" to="body">
    <div
      class="fixed z-50 inset-0 overflow-y-auto bg-white bg-opacity-90 backdrop-blur-sm show-bg"
      @click="modelValue = !modelValue"
    >
      <div class="inset-center w-full md:w-auto" @click.stop>
        <div
          class="bg-milky-200 rounded-lg overflow-hidden shadow-xl pl-4 pt-12 pr-6 pb-20 md:pl-10 md:pt-12 md:pr-16 md:pb-20 w-full md:max-w-[604px] show-container"
          role="dialog"
          aria-modal="false"
          aria-labelledby="modal-headline"
        >
          <div v-bind="$attrs" class="flex flex-col">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
@keyframes showContainer {
  0% {transform: scale(0.8)}
  100% {transform: scale(1)}
}

@keyframes showBg {
  0% {opacity: 0}
  100% {opacity: 1}
}

.inset-center {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-container {animation: showContainer ease-in-out 150ms}
.show-bg {animation: showBg ease-in-out 150ms}
</style>
